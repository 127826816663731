// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'
import '@/permission'
import Vue from 'vue'
import App from './App'
import router from './router'

import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import globals from './globals'
import Popper from 'popper.js'
import messages from '@/mixins/messages'
import flatpickr from 'flatpickr'
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
flatpickr.localize(Turkish)

// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
// Global RTL flag
Vue.mixin({
  data: globals
})

Vue.mixin(messages)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
