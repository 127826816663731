<template>
  <nav class="layout-footer footer" :class="getLayoutFooterBg()">
    <div class="container-fluid container-p-x pb-3">
      <b-dd-item :to="{name:homePageComponentName}" class="footer-link pt-3">Anasayfa</b-dd-item>
      <b-dd-item :to="{name:profileComponentName}" class="footer-link pt-3">Profil</b-dd-item>
    </div>
  </nav>
</template>

<script>
import { getAuthorityFromToken } from '@/utils/jwtParser'

export default {
  name: 'app-layout-footer',

  methods: {
    getAuthority () {
      this.authority = getAuthorityFromToken()
    },
    getLayoutFooterBg () {
      return `bg-${this.layoutFooterBg}`
    }
  },
  computed: {
    profileComponentName () {
      if (this.authority === 'Student') {
        return 'StudentProfile'
      } else if (this.authority === 'Teacher') {
        return 'TeacherProfile'
      } else if (this.authority === 'Admin') {
        return 'AdminProfile'
      }
      return ''
    },
    homePageComponentName () {
      if (this.authority === 'Student') {
        return 'StudentHome'
      } else if (this.authority === 'Teacher') {
        return 'TeacherHome'
      } else if (this.authority === 'Admin') {
        return 'AdminHome'
      }
      return ''
    }
  },
  data () {
    return {
      authority: null
    }
  },
  beforeMount () {
    this.getAuthority()
  }
}
</script>
