<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="scss" src="@/vendor/styles/bootstrap.scss"></style>
<style lang="scss" src="@/vendor/styles/appwork.scss"></style>
<style lang="scss" src="@/vendor/styles/theme-corporate.scss"></style>
<style lang="scss" src="@/vendor/styles/colors.scss"></style>
<style lang="scss" src="@/vendor/styles/uikit.scss"></style>
<style lang="scss" src="./style.scss"></style>

<script>
export default {
  name: 'app',
  metaInfo: {
    title: 'Index',
    titleTemplate: '%s - Ulubilder Online Eğitim'
  },
  updated () {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 1)
  },
  created () {
    /*
    const url = window.location.href
    if (url.includes('http://portal.')) {
      window.location = url.replace('http://portal.', 'https://portal.')
    }

     */
  }
}
</script>

<style>
.modal-body {
  white-space: pre-line !important;
}

html {
  margin: 0;
  padding: 0;
}

html, body {
  min-height: 100%;
}
</style>
