import Cookies from 'js-cookie'
export const getToken = cookieName => Cookies.get(cookieName)
export const setToken = (cookieName, cookieValue, cookieTime = '4m') => {
  if (getToken(cookieName) != null) {
    removeToken(cookieName)
  }
  Cookies.set(cookieName, cookieValue, { expires: 500 })
}
export const removeToken = cookieName => Cookies.remove(cookieName)

export const setCookie = (cookieName, cookieValue, minute) => {
  if (getToken(cookieName) != null) {
    removeToken(cookieName)
  }
  const date = new Date()
  date.setTime(date.getTime() + (minute * 60 * 1000))
  Cookies.set(cookieName, cookieValue, { expires: date })
}

export const setSecondCookie = (cookieName, cookieValue, second) => {
  if (getToken(cookieName) != null) {
    removeToken(cookieName)
  }
  const date = new Date()
  date.setTime(date.getTime() + (second * 1000))
  Cookies.set(cookieName, cookieValue, { expires: date })
}
