import { getToken } from './auth'

export function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
  return JSON.parse(jsonPayload)
}

export function getAuthorityFromToken () {
  const parsedJWT = parseJwt(getToken('tokenUlubilderOnlineEgitim'))
  return parsedJWT.role
}

export function getNameFromToken () {
  const parsedJWT = parseJwt(getToken('tokenUlubilderOnlineEgitim'))
  return parsedJWT.unique_name
}

export function getUserIdFromToken () {
  const parsedJWT = parseJwt(getToken('tokenUlubilderOnlineEgitim'))
  return parsedJWT.nameid
}
