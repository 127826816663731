import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'
import Layout from '@/layout/Layout1'
Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '/ogrenci-anasayfa',
          meta: [{
            Roles: ['Student']
          }],
          component: () => import('@/views/Student/StudentHome')
        },
        {
          path: '/admin-anasayfa',
          meta: [{
            Roles: ['Admin']
          }],
          component: () => import('@/views/Admin/AdminHome')
        },
        {
          path: '/ogretmen-anasayfa',
          meta: [{
            Roles: ['Teacher']
          }],
          component: () => import('@/views/Teacher/TeacherHome')
        }]
    },
    {
      path: '/giris',
      name: 'Login',
      component: () => import('@/views/Auth/Login')
    },
    {
      path: '/kayit',
      name: 'studentRegister',
      component: () => import('@/views/Auth/StudentRegister')
    }, {
      path: '/ogretmen-kayit',
      name: 'teacherRegister',
      component: () => import('@/views/Auth/TeacherRegister')
    },
    {
      path: '/ogrenci',
      component: Layout,
      children: [{
        path: '/ogrenci-anasayfa',
        name: 'StudentHome',
        meta: [{
          Roles: ['Student']
        }],
        component: () => import('@/views/Student/StudentHome')
      },
      {
        path: '/ogrenci-sinif',
        name: 'StudentClassroom',
        meta: [{
          Roles: ['Student']
        }],
        component: () => import('@/views/Student/StudentClassroom')
      },
      {
        path: '/ogrenci-profili',
        name: 'StudentProfile',
        meta: [{
          Roles: ['Student']
        }],
        component: () => import('@/views/Student/StudentProfile')
      }
      ]
    },
    {
      path: '/ogretmen',
      component: Layout,
      children: [{
        path: '/ogretmen-anasayfa',
        name: 'TeacherHome',
        meta: [{
          Roles: ['Teacher']
        }],
        component: () => import('@/views/Teacher/TeacherHome')
      },
      {
        path: '/ogretmen-profili',
        name: 'TeacherProfile',
        meta: [{
          Roles: ['Teacher']
        }],
        component: () => import('@/views/Teacher/TeacherProfile')
      },
      {
        path: '/ogretmen-sinif-listesi',
        name: 'TeacherClassroomList',
        meta: [{
          Roles: ['Teacher']
        }],
        component: () => import('@/views/Teacher/TeacherClassroomList')
      }
      ]
    },
    {
      path: '/admin',
      component: Layout,
      children: [{
        path: '/admin-anasayfa',
        name: 'AdminHome',
        meta: [{
          Roles: ['Admin']
        }],
        component: () => import('@/views/Admin/AdminHome')
      },
      {
        path: '/admin-profil',
        name: 'AdminProfile',
        meta: [{
          Roles: ['Admin']
        }],
        component: () => import('@/views/Admin/AdminProfile')
      },
      {
        path: '/admin-ogretmen-listesi',
        name: 'AdminTeacherList',
        meta: [{
          Roles: ['Admin']
        }],
        component: () => import('@/views/Admin/AdminTeacherList')
      },
      {
        path: '/admin-ogrenci-listesi',
        name: 'AdminStudentList',
        meta: [{
          Roles: ['Admin']
        }],
        component: () => import('@/views/Admin/AdminStudentList')
      },
      {
        path: '/admin-sinif-listesi',
        name: 'AdminClassroomList',
        meta: [{
          Roles: ['Admin']
        }],
        component: () => import('@/views/Admin/AdminClassroomList')
      },
      {
        path: '/admin-ders-listesi',
        name: 'AdminLectureList',
        meta: [{
          Roles: ['Admin']
        }],
        component: () => import('@/views/Admin/AdminLectureList')
      }
      ]
    },
    {
      // 404 Not Found page
      path: '*',
      component: NotFound
    },
    {
      path: '/parolami-unuttum',
      name: 'forgotPassword',
      component: () => import('@/views/Auth/ForgotPassword')
    }]
})

export default router
