<template>
  <b-navbar
    toggleable="lg"
    :variant="getLayoutNavbarBg()"
    class="layout-navbar align-items-lg-center container-p-x"
  >
    <!-- Brand -->
    <b-navbar-brand :to="{name: homePageComponentName}">Peykhane Menü</b-navbar-brand>

    <!-- Sidenav toggle -->
    <b-navbar-nav
      class="align-items-lg-center mr-auto mr-lg-4"
      v-if="sidenavToggle"
    >
      <a
        class="nav-item nav-link px-0 ml-2 ml-lg-0"
        @click="toggleSidenav"
      >
        <i class="ion ion-md-menu text-large align-middle" />
      </a>
    </b-navbar-nav>

    <!-- Navbar toggle -->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-navbar-nav class="align-items-lg-center ml-auto">
      <b-nav-item-dropdown class="demo-navbar-user">
        <template slot="button-content">
            <span class="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
              <span class="px-1 mr-lg-2 ml-2 ml-lg-0">{{userName}}</span>
            </span>
        </template>
        <b-dd-item :to="{name: profileComponentName}"><i class="ion ion-md-settings text-secondary"></i>  Hesap Ayarlarım</b-dd-item>
        <b-dd-item :to="{name: 'Login'}" @click="signOut"><i class="ion ion-ios-log-out text-danger"></i>  Çıkış Yap</b-dd-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { getAuthorityFromToken, getNameFromToken } from '@/utils/jwtParser'
import { removeToken } from '@/utils/auth'

export default {
  name: 'app-layout-navbar',
  mounted () {
    this.getCurrentUserName()
  },
  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      userName: ''
    }
  },
  methods: {
    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    },
    signOut () {
      removeToken('tokenUlubilderOnlineEgitim')
      this.$router.push('/giris')
    },
    getLayoutNavbarBg () {
      return this.layoutNavbarBg
    },
    getCurrentUserName () {
      this.userName = getNameFromToken()
    },
    getAuthority () {
      this.authority = getAuthorityFromToken()
    }
  },
  beforeMount () {
    this.getAuthority()
  },
  computed: {
    profileComponentName () {
      if (this.authority === 'Student') {
        return 'StudentProfile'
      } else if (this.authority === 'Teacher') {
        return 'TeacherProfile'
      } else if (this.authority === 'Admin') {
        return 'AdminProfile'
      }
      return ''
    },
    homePageComponentName () {
      if (this.authority === 'Student') {
        return 'StudentHome'
      } else if (this.authority === 'Teacher') {
        return 'TeacherHome'
      } else if (this.authority === 'Admin') {
        return 'AdminHome'
      }
      return ''
    }
  }
}
</script>
