<template>
  <sidenav :orientation="orientation" :class="curClasses">

    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">

     <div v-if="authority==='Admin'">
       <sidenav-router-link icon="sidenav-icon ion ion-md-speedometer" :to="{name:'AdminHome'}" :exact="true">Anasayfa</sidenav-router-link>
       <sidenav-router-link icon="fa fa-list" :to="{name:'AdminStudentList'}" :exact="true">Öğrenci Listesi</sidenav-router-link>
       <sidenav-router-link icon="fa fa-list" :to="{name:'AdminTeacherList'}" :exact="true">Öğretmen Listesi</sidenav-router-link>
       <sidenav-router-link icon="fa fa-list" :to="{name:'AdminClassroomList'}" :exact="true">Sınıf Listesi</sidenav-router-link>
       <sidenav-router-link icon="fa fa-list" :to="{name:'AdminLectureList'}" :exact="true">Ders Listesi</sidenav-router-link>
       <sidenav-router-link icon="ion ion-ios-contact" :to="{name: 'AdminProfile'}" :exact="true">Hesap Ayarlarım</sidenav-router-link>
     </div>

      <div v-if="authority==='Teacher'">
        <sidenav-router-link icon="sidenav-icon ion ion-md-speedometer" :to="{name:'TeacherHome'}" :exact="true">Anasayfa</sidenav-router-link>
        <sidenav-router-link icon="fa fa-list" :to="{name:'TeacherClassroomList'}" :exact="true">Sınıf Listesi</sidenav-router-link>
        <sidenav-router-link icon="ion ion-ios-contact" :to="{name: 'TeacherProfile'}" :exact="true">Hesap Ayarlarım</sidenav-router-link>
      </div>

      <div v-if="authority==='Student'">
        <sidenav-router-link icon="sidenav-icon ion ion-md-speedometer" :to="{name:'StudentHome'}" :exact="true">Anasayfa</sidenav-router-link>
        <sidenav-router-link icon="fa fa-chalkboard-teacher" :to="{name:'StudentClassroom'}" :exact="true">Sınıfım</sidenav-router-link>
        <sidenav-router-link icon="ion ion-ios-contact" :to="{name: 'StudentProfile'}" :exact="true">Hesabım</sidenav-router-link>
      </div>

    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from '@/vendor/libs/sidenav'
import { getAuthorityFromToken } from '@/utils/jwtParser'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },
  data () {
    return {
      authority: ''
    }
  },

  computed: {
    curClasses () {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },
  beforeMount () {
    this.getAuthority()
  },
  methods: {
    getAuthority () {
      this.authority = getAuthorityFromToken()
    },
    isMenuActive (url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen (url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav () {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
