export default {
  methods: {
    async showErrorMessage (message = '', title = 'Hata') {
      await this.$bvModal.msgBoxOk(message, {
        title: title,
        headerBgVariant: 'danger',
        headerTextVariant: 'light',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'danger',
        headerClass: 'p-2 border-bottom-0',
        bodyClass: 'modalCustomBody',
        footerClass: 'p-2 border-top-0'
      })
    },
    async showSuccessMessage (message = 'İşleminiz başarılı bir şekilde yapıldı.', title = 'Başarılı') {
      await this.$bvModal.msgBoxOk(message, {
        title: title,
        headerBgVariant: 'success',
        headerTextVariant: 'light',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'success',
        headerClass: 'p-2 border-bottom-0',
        bodyClass: 'modalCustomBody',
        footerClass: 'p-2 border-top-0'
      })
    },
    async showInfoMessage (message, title = 'Bilgi') {
      await this.$bvModal.msgBoxOk(message, {
        title: title,
        headerBgVariant: 'info',
        headerTextVariant: 'light',
        size: 'md',
        buttonSize: 'md',
        okVariant: 'info',
        headerClass: 'p-2 border-bottom-0',
        bodyClass: 'modalCustomBody',
        footerClass: 'p-2 border-top-0'
      })
    }
  }
}
