import router from './router'
import globals from '@/globals'
import { getToken } from '@/utils/auth'
import { getAuthorityFromToken } from '@/utils/jwtParser'

const whiteList = ['/giris', '/kayit', '/ogretmen-kayit', '/parolami-unuttum']

router.afterEach((to, from) => {
  const splashScreen = document.querySelector('.app-splash-screen')
  if (splashScreen) {
    splashScreen.style.opacity = 0
    setTimeout(() => splashScreen && splashScreen.parentNode.removeChild(splashScreen), 300)
  }
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  if (to.path) {
    if (to.path === '/404') {
      setTimeout(() => next(), 10)
    } else {
      if (getToken('tokenUlubilderOnlineEgitim')) {
        const yetki = getAuthorityFromToken()
        if (to.path === '/') {
          if (yetki === 'Student') {
            setTimeout(() => next({ name: 'StudentHome' }), 10)
          } else if (yetki === 'Teacher') {
            setTimeout(() => next({ name: 'TeacherHome' }), 10)
          } else if (yetki === 'Admin') {
            setTimeout(() => next({ name: 'AdminHome' }), 10)
          }
        }
        if (to.meta && to.meta[0]) {
          const yetkiler = to.meta[0].Roles
          if (yetkiler) {
            if (yetkiler.indexOf(yetki) !== -1) {
              setTimeout(() => next(true), 10)
            } else {
              if (yetki === 'Student') {
                setTimeout(() => next({ name: 'StudentHome' }), 10)
              } else if (yetki === 'Teacher') {
                setTimeout(() => next({ name: 'TeacherHome' }), 10)
              } else if (yetki === 'Admin') {
                setTimeout(() => next({ name: 'AdminHome' }), 10)
              }
            }
          } else {
            setTimeout(() => next(true), 10)
          }
        } else {
          setTimeout(() => next(true), 10)
        }
      } else {
        if (whiteList.indexOf(to.path) !== -1) {
          setTimeout(() => next(true), 10)
        } else {
          setTimeout(() => next({ path: '/giris', query: { returnUrl: to.path } }), 10)
        }
      }
    }
  } else {
    setTimeout(() => next({ path: '/404' }), 10)
  }
})
